import clsx from "clsx";

import { FC, useEffect, useState } from "react";

import SunEditor from "suneditor-react";

import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

// import SunEditorCore from 'suneditor/src/lib/core'

import {
  align,
  blockquote,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  image,
  lineHeight,
  link,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
} from "suneditor/src/plugins";

import { useLang } from "../../../_metronic/i18n/Metronici18n";

import "./TextEditor.scss";

import { useIntl } from "react-intl";

interface Props {
  onChange?: (data: any) => void;
  handleBlur?: (val: string) => void;
  initHtml: string | null;
  size?: "xsmall" | "small" | "medium" | "full";
  hideSubmit?: boolean;
  onSubmit?: (html: string) => void;
  loading?: boolean;
  wrapperPosition: number;
  uniqueClassName?: string;
  isDiasbled?: boolean;
}

const SunEditorComponent: FC<Props> = ({
  uniqueClassName = "sun-editor-custom",
  onChange,
  handleBlur,
  initHtml,
  size = "medium",
  hideSubmit,
  onSubmit,
  loading,
  wrapperPosition,
  isDiasbled,
}) => {
  const lang = useLang();
  const intl = useIntl();
  const [sunValue, setSunValue] = useState(initHtml || "");
  // const [htmlValue, setHtmlValue] = useState('')
  const [bool, setBool] = useState(false);
  // const editor = useRef<SunEditorCore>()
  // // The sunEditor parameter will be set to the core suneditor instance when this function is called
  // const getSunEditorInstance = (sunEditor: SunEditorCore) => {
  //   editor.current = sunEditor
  // }

  useEffect(() => {
    if (initHtml && initHtml.length !== sunValue.length) {
      // const data = initHtml
      setSunValue(initHtml);
    }
    if (initHtml === "" && onChange) {
      onChange("");
    }
  }, [initHtml]);

  const handleFullScreen = (bool: boolean) => {
    if (uniqueClassName) {
      const ele = document.getElementsByClassName(uniqueClassName)[1];
      if (bool) {
        ele.classList.add("rl-full");
      } else {
        ele.classList.remove("rl-full");
      }
    }
  };

  return (
    <div className={bool ? "showRaw customTool " : "customTool"}>
      <SunEditor
        // ref={editorRef}
        // defaultValue={initHtml || ''}
        // getSunEditorInstance={getSunEditorInstance}
        setContents={sunValue || ""}
        setOptions={{
          className: clsx(
            uniqueClassName,

            size === "xsmall"
              ? "rl-xsmall"
              : size === "small"
                ? "rl-small"
                : "rl-medium"
          ),
          value: sunValue || "",
          showPathLabel: false,
          minHeight: "10em",
          maxHeight: "15em",
          height: "auto",
          plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            template,
            textStyle,
            image,
            link,
            blockquote,
          ],

          buttonList: [
            ["undo", "redo"],
            ["formatBlock"],
            //   ['font', 'fontSize', 'formatBlock'],
            // ['bold', 'underline', 'italic', 'strike'],
            [
              "bold",
              "underline",
              "italic",
              "strike",
              "subscript",
              "superscript",
            ],
            ["outdent", "indent"],
            ["align", "list"],
            //   ['align', 'horizontalRule', 'list', 'lineHeight'],
            // ["table", "link", "image"],
            ["blockquote"],
            ["fullScreen", "codeView"],
            //   ['paragraphStyle'],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            ["preview", "print"],
            "/", // Line break
          ],
          formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
          font: [
            "Arial",
            "Calibri",
            "Comic Sans",
            "Courier",
            "Garamond",
            "Georgia",
            "Impact",
            "Lucida Console",
            "Palatino Linotype",
            "Segoe UI",
            "Tahoma",
            "Times New Roman",
            "Trebuchet MS",
          ],
        }}
        autoFocus={false}
        lang={lang || "de"}
        toggleCodeView={(e) => {
          if (e) {
            document.getElementsByClassName("se-wrapper")[
              wrapperPosition
              //@ts-ignore
            ].style.display = "none";
            document.getElementsByClassName(
              "se-resizing-bar sun-editor-common"
            )[
              wrapperPosition
              //@ts-ignore
            ].style.display = "none";
          } else {
            document.getElementsByClassName("se-wrapper")[
              wrapperPosition
              //@ts-ignore
            ].style.display = "block";
          }
          onChange?.(sunValue);
          setBool(e);
        }}
        onChange={(e) => {
          setSunValue(e);
          onChange?.(e);
        }}
        toggleFullScreen={(isFull) => {
          handleFullScreen(isFull);
        }}
        onBlur={(e) => {
          //@ts-ignore
          handleBlur?.(e.target.innerText);
          let editor = document?.querySelectorAll("div.se-wrapper-inner");
          Array.from(editor)?.forEach((item) => {
            if (
              item?.getElementsByTagName("p")?.[0]?.innerHTML === "<br>" &&
              item?.getElementsByTagName("p")?.[1]?.innerHTML.length > 0
            ) {
              item?.removeChild(item?.getElementsByTagName("p")?.[0]);
            }
          });
        }}
        disable={isDiasbled}
      />
      <textarea
        name=""
        id=""
        value={sunValue}
        style={{
          width: "100%",
          height: "100%",
          resize: "none",
          minHeight: "10em",
          maxHeight: "15em",
          borderTop: "none",
        }}
        contentEditable={false}
        className={
          (clsx(
            size === "xsmall"
              ? "rl-xsmall"
              : size === "small"
                ? "rl-small"
                : "rl-medium"
          ),
          "raw-editor w-100 position-relative se-wrapper-inner se-wrapper-wysiwyg sun-editor-editable")
        }
        onChange={(e) => {
          // const val = e.target.value
          // setHtmlValue(val)
        }}
        onBlur={(e) => {}}
        disabled={isDiasbled}
      ></textarea>
      {hideSubmit && (
        <div className="d-flex mt-5 flex-row justify-content-end">
          <button
            className="btn btn-primary  min-w-100px"
            onClick={() => {
              onSubmit?.(sunValue.toString());
            }}
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({ id: "EDITOR_SAVE_BUTTON" })}
              </span>
            )}

            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({ id: "EDITOR_PLEASE_WAIT_MESSAGE" })}

                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default SunEditorComponent;
