import { useEffect, useState } from "react";
import { FC } from "preact/compat";
import { Button, Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";

import DropDown from "../../../sharedComponents/dropdown/Dropdown";
import { SuccessAlert } from "../../../sharedComponents/Alert";
import { ModalComponentProps } from "../types/SystemMessagesPropsTypes";
import {
  createSystemMessages,
  isDefault,
  updateSystemMesasge,
} from "../redux/SystemMessagesAPI";

const MessagesAddModal: FC<ModalComponentProps> = ({
  modalProps,
  closeModal,
  getAllMessagesAPI,
  activeTab,
}) => {
  const { show, type, message } = modalProps;

  // selectors
  const intl = useIntl();
  const MASTERDATA_SYSTEM_MESSAGES_OPTIONS = JSON.parse(
    intl.formatMessage({ id: "MASTERDATA_SYSTEM_MESSAGES_OPTIONS" })
  ) as { id: number; name: string; title: string }[];

  // state
  const [loading, setLoading] = useState(false);
  const [selectedMessageType, setSelectedMessageType] = useState<{
    id: number;
    name: string;
    title: string;
  }>();

  const initialValues = {
    // workflow: type === "edit" ? message?.workflow : activeTab,
    message: type === "edit" ? message?.message : "",
    is_default: type ==="edit" ? message?.is_default : ""
  };

  const AddNewMessagesSchema = Yup.object().shape({
    // workflow: Yup.string().required(
    //   intl.formatMessage({
    //     id: "MASTERDATA_SYSTEM_MESSAGE_WORK_FLOW_REQUIRED_MESSAGE",
    //   })
    // ),
    is_default: Yup.boolean(),
    message: Yup.string().required(
      intl.formatMessage({
        id: "MASTERDATA_SYSTEM_MESSAGE_MESSAGE_REQUIRED_MESSAGE",
      })
    ),
  });

  useEffect(() => {
    if (type === "edit") {
      const currentMessage = MASTERDATA_SYSTEM_MESSAGES_OPTIONS.filter(
        (data) => data.name === message?.workflow
      )[0];
      if (currentMessage && currentMessage.id) {
        setSelectedMessageType(currentMessage);
      }
    }
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: AddNewMessagesSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (type === "new" && values.message && activeTab) {
        setLoading(true);
        createSystemMessages(activeTab, values.message, values.is_default ? 1 : undefined)
          .then(() => {
            closeModal();
            SuccessAlert(
              intl.formatMessage({
                id: "MASTERDATA_SYSTEM_MESSAGE_ADD_SUCCESS_MESSAGE",
              }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
          })
          .catch((err) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGE_ADD_FAILURE_MESSAGE",
                  })
                );
          })
          .finally(() => {
            setLoading(false);
            getAllMessagesAPI();
          });
      } else {
        if (
          type === "edit" &&
          message?.id &&
          values.message &&
          activeTab
        ) {
          setLoading(true)
          const apiCalls = [
            updateSystemMesasge(
              message.id,
              activeTab,
              values.message,
              values.is_default ? 1 : undefined
            )
          ];
          Promise.all(apiCalls)
          .then(() => {
            closeModal();
      
            SuccessAlert(
              intl.formatMessage({
                id: "MASTERDATA_SYSTEM_MESSAGE_UPDATE_SUCCESS_MESSAGE",
              }),
              () => {},
              intl.formatMessage({ id: "ALERT_SUCCESS_MESSAGE" })
            );
      
            getAllMessagesAPI();
          })
          .catch((err) => {
            err.response?.data?.errors?.name
              ? setStatus(err.response.data?.errors?.name)
              : setStatus(
                  intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGE_UPDATE_FAILURE_MESSAGE",
                  })
                );
          })
          .finally(() => {
            setLoading(false);
          });
        }
      }
    },
  });

  return (
    <Modal
      show={show}
      contentClassName={loading ? "pe-none" : "AddNew"}
      dialogClassName={"medium-size-modal"}
      centered
      backdrop="static"
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {type === "edit"
            ? intl.formatMessage({ id: "MASTERDATA_SYSTEM_MESSAGE_EDIT_MODAL_TITLE" })
            : intl.formatMessage({
                id: "MASTERDATA_SYSTEM_MESSAGE_ADD_MODAL_TITLE",
              })}
        </Modal.Title>
      </Modal.Header>

      <form
        className="form w-100 overflow-auto"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_add_category_form"
      >
        <Modal.Body className="overflow-inherit">
          <div className="px-4 py-n2">
            {formik.status && (
              <div className="alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}

             {/* Workflow */}
             {/* <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGE_ADD_WORKFLOW_TITLE",
                  })}
                </span>
              </label>

              <div className="fv-row">
                <DropDown
                  hideSearch
                  id="users-titles-dropdown"
                  items={MASTERDATA_SYSTEM_MESSAGES_OPTIONS}
                  displayFunc={(item) => {
                    // @ts-ignore
                    return item.title;
                  }}
                  displayValue={
                    MASTERDATA_SYSTEM_MESSAGES_OPTIONS.find(
                      (TITLES_OPTION) =>
                        TITLES_OPTION.name === formik.values.workflow
                    )?.title ||
                    MASTERDATA_SYSTEM_MESSAGES_OPTIONS.find(
                      (TITLES_OPTION) => TITLES_OPTION.name === activeTab
                    )?.title
                  }
                  onSelectItem={(item) => {
                    //@ts-ignore
                    formik.setFieldValue("workflow", item.name);
                    //@ts-ignore
                    setSelectedMessageType(item);
                  }}
                  selectedItem={selectedMessageType}
                />
                {formik.touched.workflow && formik.errors.workflow && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.workflow}</span>
                  </div>
                )}
              </div>
            </div> */}

             {/* Message */}
             <div className="mb-8">
              <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                <span className="required">
                  {intl.formatMessage({
                    id: "MASTERDATA_SYSTEM_MESSAGE_ADD_MESSAGE_TITLE",
                  })}
                </span>
              </label>

              <div className="fv-row">
                <textarea
                  rows={3}
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                  autoComplete="off"
                  placeholder=""
                  {...formik.getFieldProps("message")}
                  style={{ resize: "none" }}
                ></textarea>
                {formik.touched.message && formik.errors.message && (
                  <div className="text-danger">
                    <span role="alert">{formik.errors.message}</span>
                  </div>
                )}
              </div>
            </div>
            
           <div className="mb-8 d-flex align-items-center">
              <label className=" fw-bold fs-6 me-4">
                {intl.formatMessage({
                  id: "DEFAULT_MESSAGE",
                })}
              </label>
              <div className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="notifications"
                  value=""
                   checked={!!formik.values.is_default}
                  style={{ width: "50px", height: "25px" }}
                  onChange={(e) => {
                    formik.setFieldValue("is_default", e.target.checked);
                  }}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={closeModal}>
            {intl.formatMessage({ id: "CLOSE_BUTTON" })}
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {!loading && intl.formatMessage({ id: "SUBMIT_BUTTON" })}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "MASTERDATA_CATEGORY_LOADING_MESSAGE",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default MessagesAddModal;
