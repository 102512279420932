import React, { FC, useEffect, useState } from "react";
import {
  ProductActivityType,
  ProductType,
} from "../types/ProductsResponseType";
import { useIntl } from "react-intl";
import { KTSVG, SVGICON } from "../../../../_metronic/helpers";
// images
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import AddActivityModal from "./AddActivityModal";
import moment from "moment";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import Constants from "../../../config/Constants";
import { useFullName } from "../../../utils/getFullName";

interface Props {
  product: ProductType;
  getProductDetailsAPI: () => void;
  getProductActivityDetailsAPI: () => void;
  activities: ProductActivityType[] | undefined;
}
const ProductActivity: FC<Props> = ({
  product,
  getProductDetailsAPI,
  activities,
  getProductActivityDetailsAPI,
}) => {
  const intl = useIntl();
  const getFullName = useFullName();
  // state
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditActivityModal, setShowEditActivityModal] = useState<{
    show: boolean;
    activity: undefined | ProductActivityType;
  }>({
    show: false,
    activity: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [activePage, setActivePage] = useState<number>(1);

  useEffect(() => {
    setLoading(true);
    if (activities && activities.length > 0) {
      setLoading(false);
    } else if (activities && activities.length === 0) {
      setLoading(false);
    }
  }, [activities]);
  const onPageClick = (page: number) => {
    setActivePage(page);
  };

  // get paginated records
  const startIndex = itemsPerPage * (activePage - 1);
  const paginatedProductActivities =
    activities && activities.length > 10
      ? activities.slice(startIndex, startIndex + itemsPerPage)
      : activities;

  useEffect(() => {
    // @ts-ignore
    if (paginatedProductActivities?.length === 0 && activities?.length > 0) {
      setActivePage((prev) => prev - 1);
    }
  }, [paginatedProductActivities]);

  return (
    <>
      {showAddModal && (
        <AddActivityModal
          product={product}
          getProductDetailsAPI={() => {
            getProductActivityDetailsAPI();
            getProductDetailsAPI();
          }}
          show={showAddModal}
          closeModal={() => {
            setShowAddModal(false);
          }}
        />
      )}
      {showEditActivityModal.show && (
        <AddActivityModal
          product={product}
          getProductDetailsAPI={() => {}}
          show={showEditActivityModal.show}
          closeModal={() => {
            setShowEditActivityModal({
              show: false,
              activity: undefined,
            });
          }}
          activity={showEditActivityModal.activity}
        />
      )}
      <div className="card ">
        <div className="card-header ">
          <div className="border-0  d-flex flex-fill flex-row align-items-center justify-content-between">
            <div className="card-title m-0 flex-fill flex-column">
              <h3 className="fw-bolder m-0">
                {intl.formatMessage({ id: "PRODUCT_ACTIVITY_HEADER" })}
              </h3>
              <div>
                <span className="text-muted fs-8">
                  {intl.formatMessage({ id: "PRODUCT_ACTIVITY_INFO" })}
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setShowAddModal(true);
                }}
              >
                <SVGICON src={PlusIcon} className="svg-icon-2" />
                {intl.formatMessage({
                  id: "PRODUCT_ADD_ACTIVITY_BUTTON",
                })}
              </button>
            </div>
          </div>
        </div>
        <div className="card-body ">
          {loading && (
            <tr className="odd d-flex align-items-center justify-content-center">
              <td valign="top" colSpan={7} className="dataTables_empty">
                <div className="d-flex flex-column flex-center">
                  <SimpleLoader />
                </div>
              </td>
            </tr>
          )}
          {paginatedProductActivities?.length === 0 && (
            <tr className="odd d-flex align-items-center justify-content-center">
              <td valign="top" colSpan={7} className="dataTables_empty">
                <div className="d-flex flex-column flex-center">
                  <img alt="not-found" src={notFound} className="mw-400px" />
                  <div className="fs-1 fw-bolder text-dark mb-4">
                    {intl.formatMessage({
                      id: "PRODUCT_ACTIVITY_LIST_NOITEMS_FOUND",
                    })}
                  </div>
                </div>
              </td>
            </tr>
          )}
          <div className="timeline">
            {/* <Item1 />
            <Item2 /> */}
            {paginatedProductActivities &&
              paginatedProductActivities.length > 0 &&
              paginatedProductActivities.map((activity) => (
                <div className="timeline-item">
                  <div className="timeline-line w-40px"></div>

                  <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                    <div className="symbol-label bg-light">
                      <KTSVG
                        path="/media/icons/duotune/communication/com003.svg"
                        className="svg-icon-2 svg-icon-gray-500"
                      />
                    </div>
                  </div>

                  <div className="timeline-content mb-10 mt-n1">
                    <div
                      className="pe-3 mb-5"
                      role="button"
                      onClick={() => {
                        setShowEditActivityModal({
                          show: true,
                          activity: activity,
                        });
                      }}
                    >
                      <div className="fs-5 fw-bold mb-2">
                        {activity.units} unit(s){" "}
                        {activity.input
                          ? intl.formatMessage({
                              id: "PRODUCTS_ACTIVITY_ADDED_TEXT",
                            })
                          : intl.formatMessage({
                              id: "PRODUCTS_ACTIVITY_REMOVED_TEXT",
                            })}{" "}
                        {activity.input
                          ? intl.formatMessage({
                              id: "PRODUCTS_ACTIVITY_TO_TEXT",
                            })
                          : intl.formatMessage({
                              id: "PRODUCTS_ACTIVITY_FROM_TEXT",
                            })}{" "}
                        the Stock
                        {activity.input
                          ? intl.formatMessage({
                              id: "PRODUCTS_ACTIVITY_PRICE_TEXT",
                            }) + activity?.price
                          : ""}
                      </div>

                      <div className="d-flex align-items-center mt-1 fs-6">
                        <div className="text-muted me-2 fs-7">
                          {"Updated at"}{" "}
                          {moment(activity.created_at).format(
                            "YYYY-MM-DD h:mm"
                          )}{" "}
                          {"by"}
                        </div>
                        <div className="d-flex  align-items-center">
                          <span className="badge badge-light-success fw-bolder    me-2">
                            {(activity.user_details?.firstname &&
                              activity.user_details?.lastname &&
                              getFullName(
                                undefined,
                                activity.user_details?.firstname,
                                activity.user_details?.lastname
                              )) ||
                              activity.user_details?.username ||
                              ""}
                          </span>
                          <span className="badge badge-light-info fw-bolder    me-2">
                            {activity.user_details?.role || ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {activities && activities.length > 0 && (
          <div>
            <Pagination
              totalPages={Math.ceil(activities.length / itemsPerPage)}
              activePage={
                Math.ceil(activities.length / itemsPerPage) === 1
                  ? 1
                  : activePage
              }
              onPageClick={onPageClick}
              noCard
              itemsPerPage={itemsPerPage}
              showItemsPerPage
              setItemsPerPage={(count) => {
                setItemsPerPage(count);
                setActivePage(1);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ProductActivity;
