import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// images
import CloseIcon from "../../../../_metronic/assets/icons/close.svg";
import FilterIcon from "../../../../_metronic/assets/icons/filter.svg";
import notFound from "../../../../_metronic/assets/icons/notFound.png";
import PlusIcon from "../../../../_metronic/assets/icons/plus.svg";
import SearchIcon from "../../../../_metronic/assets/icons/search.svg";

// custom
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { RootState } from "../../../../setup";
import { SVGICON } from "../../../../_metronic/helpers";
// import {useLang} from '../../../../_metronic/i18n/Metronici18n'
import Constants from "../../../config/Constants";
import Checkbox from "../../../sharedComponents/Checkbox/Checkbox";
import SimpleLoader from "../../../sharedComponents/Loader/SimpleLoader";
import Pagination from "../../../sharedComponents/pagination/Pagination";
import { useAddress } from "../../../utils/getAddress";
import { useFullName } from "../../../utils/getFullName";
import { UserType } from "../../auth/types/LoginResponseTypes";
import { actions } from "../redux";
import { getCustomersListByFilters } from "../redux/CustomerManagementAPI";
import { CustomersFilterType } from "../types/getCustomersListResponseType";
import AddCustomerModal from "./AddCustomerModal";
import CustomersListFilterModal from "./CustomersListFilterModal";
import UsersListActionsDropdown from "./UsersListActionsDropdown";
import {
  PaginationType,
  PartnersContactDetailType,
} from "../../partnerManagement/types/getPartnersListResponseType";
import { RoleType } from "../../RolesAndPermissions/types/getRolesResponseType";
import { enablePermissionForCrud } from "../../../utils/PermisisionEnabledForResource";

interface Props {
  type?: string;
  onSelectCustomers?: (customers: UserType[]) => void;
  isSelectionModal?: boolean;
  selectedContacts?: PartnersContactDetailType[];
  setIsFilterOpen?: any;
}

const CustomersList: FC<Props> = ({
  type,
  onSelectCustomers,
  isSelectionModal,
  selectedContacts,
  setIsFilterOpen,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();
  // const lang = useLang()
  const getAddress = useAddress();
  const getFullName = useFullName();

  const [selectFilters, setSelectFilters] = useState<any>({});

  const filterData = useSelector<RootState>(
    // @ts-ignore
    ({ customerManagement }) => customerManagement.filters
  ) as CustomersFilterType;

  // selectors
  const filters =
    isSelectionModal || type === "select" ? selectFilters : filterData;

  const search = useSelector<RootState>(
    // @ts-ignore
    ({ customerManagement }) => customerManagement.search
  ) as string;

  const customersActivePage = useSelector<RootState>(
    // @ts-ignore
    ({ customerManagement }) => customerManagement.activePage
  ) as number;

  // state
  const [customersList, setCustomersList] = useState<UserType[]>([]);
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false);
  const [newUserCreated, setNewUserCreated] = useState<any>([]);
  const [showCustomersFilterModal, setShowCustomersFilterModal] =
    useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(
    type === "select" ? 1 : customersActivePage
  );
  const [searchText, setSearchText] = useState<string>(
    type === "select" ? "" : search
  );
  const [getCustomersListAPICompleted, setGetCustomersListAPICompleted] =
    useState<boolean>(false);
  const [paginationDetails, setPaginationDetails] = useState<PaginationType>();
  const [tempSearchText, setTempSearchText] = useState<string>(
    type === "select" ? "" : search
  );
  const [APIloading, setAPILoading] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(
    Constants.defaultItemsPerPageCount
  );
  const [checkedCustomers, setCheckedCustomers] = useState<UserType[]>([]);
  const [checkAllItems, setCheckAllItems] = useState<boolean>(false);
  const userRoleDetails = useSelector<RootState>(
    // @ts-ignore
    (state) => state.auth.roleDetails
  ) as RoleType;
//@ts-ignore
 const crudPermission:any = (enablePermissionForCrud(userRoleDetails, 'contacts'))

  const closeModal = () => {
    setShowAddCustomerModal(false);
    if (isSelectCustomerType()) {
      setIsFilterOpen(false);
    }
  };

  const getCustomersListAPI = (page?: number) => {
    // fallback to  the current page
    if (page && activePage !== page) {
      setActivePage(page);
      !isSelectCustomerType() && dispatch(actions.setCustomersActivePage(page));
    }
    setGetCustomersListAPICompleted(false);
    setAPILoading(true);
    const labels =
      filters.labels && filters.labels.length > 0
        ? filters.labels.map((label: any) => {
            return label.id;
          })
        : null;
    getCustomersListByFilters(
      filters.is_blocked,
      filters.product_id,
      filters.subscription_id,
      filters.zip_code,
      filters.city,
      filters.country?.id || null,
      labels,
      itemsPerPage,
      searchText,
      page ? page : activePage
    )
      .then(({ data: { contacts = [], contacts_pagination } }) => {
        setCustomersList(contacts);
        setPaginationDetails(contacts_pagination);
      })
      .catch(() => {})
      .finally(() => {
        setGetCustomersListAPICompleted(true);
        setAPILoading(false);
        if (isSelectCustomerType()) {
          setIsFilterOpen(false);
        }
      });
  };

  // effects
  useEffect(() => {
    getCustomersListAPI(activePage);

    return () => {
      setCustomersList([]);
    };
  }, [searchText, filters, itemsPerPage]);

  const onPageClick = (page: number) => {
    setActivePage(page);
    !isSelectCustomerType() && dispatch(actions.setCustomersActivePage(page));
    getCustomersListAPI(page);
  };

  const onSearchTextChange = (text: string) => {
    setTempSearchText(text || "");
  };

  const hasFilters = (filters: CustomersFilterType) => {
    if (
      (filters.labels && filters.labels.length > 0) ||
      // filters.is_blocked ||
      filters.country ||
      filters.city ||
      filters.zip_code
      // filters.product_id ||
      // filters.subscription_id
    ) {
      return true;
    }
    return false;
  };

  const isSelectCustomerType = () => {
    return type === "select";
  };

  const onAllItemsCheckChange = () => {
    const updatedCheckAllItems = !checkAllItems;
    setCheckAllItems(updatedCheckAllItems);
    if (updatedCheckAllItems) {
      //@ts-ignore
      let avoidSelected = customersList?.filter(
        (item) => !selectedContacts?.map((item) => item.id)?.includes(item.id)
      );
      setCheckedCustomers(avoidSelected);
    } else {
      setCheckedCustomers([]);
    }
  };

  const onCheckedChange = (customer: UserType) => {
    setNewUserCreated([]);
    if (isSelectCustomerType()) {
      setCheckedCustomers([customer]);
    } else {
      let checkedCustomersCopy = _.clone(checkedCustomers);
      const index = checkedCustomersCopy.findIndex(
        (checkedCustomer) => checkedCustomer.id === customer.id
      );
      if (index > -1) {
        checkedCustomersCopy.splice(index, 1);
        setCheckAllItems(false);
      } else {
        checkedCustomersCopy.push(customer);
      }
      setCheckedCustomers(checkedCustomersCopy);
    }
  };

  const isChecked = (customer: UserType) => {
    const index = checkedCustomers.findIndex(
      (checkedCustomer) => checkedCustomer.id === customer.id
    );
    if (index > -1) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (tempSearchText.length === 0) {
      setTempSearchText("");
      setSearchText("");
      !isSelectCustomerType() && dispatch(actions.setCustomerSearch(""));
    }
  }, [tempSearchText]);

  useEffect(() => {
    // const getProductsListAPI = () => {
    //   getProducts().then(({ data: { stripeProductDetails } }) => {
    //     dispatch(productActions.setProductsList(stripeProductDetails));
    //   });
    // };
    // const getSubscriptionListAPI = () => {
    //   getSubscriptions().then(({ data: { stripeSubscriptionDetails } }) => {
    //     dispatch(
    //       subscriptionstActions.setSubscriptionsList(stripeSubscriptionDetails)
    //     );
    //   });
    // };
    // getProductsListAPI();
    // getSubscriptionListAPI();
  }, []);

  return (
    <div className="borderNone card mt-3">
      {showAddCustomerModal && (
        <AddCustomerModal
          show={showAddCustomerModal}
          closeModal={closeModal}
          // @ts-ignore
          getCustomersListAPI={getCustomersListAPI}
          isSelectType={isSelectCustomerType()}
          onChange={(user: any) => {
            if (user) {
              // Check for duplicates before adding
              setCheckedCustomers((prevCustomers) => {
                if (
                  !prevCustomers.some(
                    (checkedCustomer) => checkedCustomer.id === user.id
                  )
                ) {
                  return [...prevCustomers, user?.personalInformation];
                }
                return prevCustomers;
              });
              setNewUserCreated(user);
            }
          }}
        />
      )}
      {showCustomersFilterModal && (
        <CustomersListFilterModal
          show={showCustomersFilterModal}
          closeModal={() => {
            setShowCustomersFilterModal(false);
          }}
          type={type}
          setSelectFilters={setSelectFilters}
          selectFilters={selectFilters}
          setIsFilterOpen={setIsFilterOpen}
        />
      )}

      {/* begin::Header */}
      <div
        className={`card-header border-0 pt-8 d-flex flex-row justify-content-between mb-2 ${
          isSelectionModal ? "pb-8" : ""
        }`}
      >
        <div className="d-flex align-items-center" style={{ borderWidth: 1 }}>
          <div className="position-relative">
            {tempSearchText && (
              <div
                className="position-absolute"
                style={{
                  right: 8,
                  top: 10,
                  zIndex: 99,
                }}
                role={"button"}
                onClick={() => {
                  setTempSearchText("");
                  setSearchText("");
                  !isSelectCustomerType() &&
                    dispatch(actions.setCustomerSearch(""));
                }}
              >
                <SVGICON src={CloseIcon} className="svg-icon svg-icon-1 " />
              </div>
            )}
            <input
              type="text"
              className="form-control form-control-solid w-250px position-relative"
              placeholder={intl.formatMessage({
                id: "PARTNERS_LIST_SEARCH_PLACEHOLDER",
              })}
              onChange={(e) => {
                onSearchTextChange(e.target.value);
              }}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                paddingRight: "30px",
              }}
              value={tempSearchText}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (tempSearchText && tempSearchText.length >= 3) {
                    setSearchText(tempSearchText);
                    !isSelectCustomerType() &&
                      dispatch(actions.setCustomerSearch(tempSearchText));
                  }
                }
              }}
            />
            {tempSearchText &&
              tempSearchText.length < 3 &&
              isSelectionModal && (
                <div
                  className="text-danger position-absolute"
                  style={{ top: "50px", width: "max-content" }}
                >
                  <div role="alert">
                    {intl.formatMessage({
                      id: "PARTNERS_LIST_SEARCH_VALIDATION_MESSAGE",
                    })}
                  </div>
                </div>
              )}
          </div>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={() => {
              if (tempSearchText && tempSearchText.length >= 3) {
                setSearchText(tempSearchText);
                !isSelectCustomerType() &&
                  dispatch(actions.setCustomerSearch(tempSearchText));
              }
            }}
            style={{
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0,
              padding: "11px",
            }}
          >
            <SVGICON src={SearchIcon} className="svg-icon svg-icon-2" />
          </button>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {checkedCustomers.length > 0 && isSelectCustomerType() ? (
            <>
              <span className="text-dark text-hover-primary fs-6 fw-bolder mx-4">
                {checkedCustomers.length}{" "}
                {intl.formatMessage({ id: "SELECTED" })}
              </span>
              <button
                className="btn  btn-primary"
                onClick={() => {
                  onSelectCustomers?.(checkedCustomers);
                }}
              >
                <span className="indicator-label">
                  {intl.formatMessage({ id: "APPLY_BUTTON" })}
                </span>
              </button>
            </>
          ) : (
            <>
              {hasFilters(filters) && (
                <>
                  {Object.keys(filters).map((key, i) => {
                    if (
                      // @ts-ignore
                      (key === "is_blocked" && filters[key]) ||
                      (key === "product_id" && filters[key]) ||
                      (key === "subscription_id" && filters[key]) ||
                      // @ts-ignore
                      !_.isEmpty(filters[key])
                    ) {
                      return (
                        <button
                          key={i}
                          id="filter"
                          className={
                            "btn   btn-light-primary btn-active-light-primary  symbol  btn-sm text-primary mx-2"
                          }
                          style={{ borderRadius: "20px" }}
                          onClick={() => {
                            const filtersCopy = { ...filters };
                            // @ts-ignore
                            filtersCopy[key] = null;
                            !isSelectCustomerType() &&
                              dispatch(actions.setFilters(filtersCopy));
                            isSelectCustomerType() &&
                              setSelectFilters(filtersCopy);
                          }}
                        >
                          <SVGICON src={CloseIcon} className="svg-icon-3" />
                          {key === "product_id"
                            ? intl.formatMessage({
                                id: "PARTNERS_LIST_FILTER_PRODUCT_KEY_NAME",
                              })
                            : key === "subscription_id"
                              ? intl.formatMessage({
                                  id: "PARTNERS_LIST_FILTER_SUBSCRIPTION_KEY_NAME",
                                })
                              : key === "is_blocked"
                                ? intl.formatMessage({
                                    id: "CUSTOMER_MANAGEMENT_TABLE_FILTER_FIELD_BLOCKED",
                                  })
                                : key === "zip_code"
                                  ? intl.formatMessage({
                                      id: "CUSTOMER_MANAGEMENT_TABLE_FILTER_FIELD_ZIP_CODE",
                                    })
                                  : key}
                        </button>
                      );
                    } else {
                      return null;
                    }
                  })}
                </>
              )}
              <button
                type="button"
                className="btn btn-primary me-4"
                onClick={() => {
                  setShowCustomersFilterModal(true);
                  type === "select" && setIsFilterOpen(true);
                }}
              >
                <SVGICON src={FilterIcon} className="svg-icon-2" />
                {intl.formatMessage({ id: "PARTNERS_LIST_TABLE_FILTER_BTN" })}
              </button>
              {/* add new customer */}
              {
               crudPermission?.create && <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setShowAddCustomerModal(true);
                    if (isSelectCustomerType()) {
                      setIsFilterOpen(true);
                    }
                  }}
                >
                  <SVGICON src={PlusIcon} className="svg-icon-2" />
                  {intl.formatMessage({
                    id: "CUSTOMER_MANAGEMENT_LIST_ADD_NEW_CUSTOMER",
                  })}
                </button>
              }
            </>
          )}
        </div>
      </div>
      {/* end::Header */}

      <div className="text-danger " style={{ padding: "0 2.25rem" }}>
        <div role="alert">
          {tempSearchText && tempSearchText.length < 3 && !isSelectionModal
            ? intl.formatMessage({
                id: "PARTNERS_LIST_SEARCH_VALIDATION_MESSAGE",
              })
            : ""}
        </div>
      </div>

      {/* begin::Body */}
      <div className="overflow-auto">
        <div
          className="card-body py-3"
          style={
            isSelectionModal
              ? { overflow: "inherit !important",  maxHeight: "calc(100vh - 400px)" }
              : { overflow: "inherit !important", }
          }
        >
          {/* begin::Table container */}
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
              {/* begin::Table head */}
              <thead>
                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                  {isSelectCustomerType() && (
                    <th className="w-25px">
                      <Checkbox
                        onChange={onAllItemsCheckChange}
                        checked={checkAllItems}
                        disabled={isSelectCustomerType()}
                      />
                    </th>
                  )}

                  <th className="min-w-125px">
                    {intl.formatMessage({
                      id: "CUSTOMER_MANAGEMENT_LIST_CUSTOMER",
                    })}
                  </th>
                  {/* <th className="min-w-125px">
                    {intl.formatMessage({
                      id: "CUSTOMER_MANAGEMENT_TABLE_COLUMN_BLOCKED",
                    })}
                  </th> */}
                  <th className="min-w-125px">
                    {intl.formatMessage({
                      id: "CUSTOMER_MANAGEMENT_TABLE_COLUMN_ADDRESS",
                    })}
                  </th>
                  <th
                    className="min-w-125px mw-150px"
                    style={{ textAlign: "center" }}
                  >
                    {intl.formatMessage({
                      id: "CUSTOMER_MANAGEMENT_TABLE_COLUMN_LABELS",
                    })}
                  </th>
                  {!isSelectCustomerType() && <th className="min-w-100px"></th>}
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {APIloading ? (
                  <tr>
                    <td valign="top" colSpan={5} className="dataTables_empty">
                      <SimpleLoader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {customersList.map((user, i) => {
                      // @ts-ignore
                      const address = getAddress(user?.personalAddress);
                      return (
                        <tr key={i}>
                          {/* checkbox*/}
                          {isSelectCustomerType() && (
                            <td>
                              <div className="form-check form-check-sm form-check-custom form-check-solid me-2">
                                <Checkbox
                                  onChange={() => {
                                    onCheckedChange(user);
                                  }}
                                  checked={
                                    isChecked(user) ||
                                    user?.id ===
                                      newUserCreated?.personalInformation?.id
                                  }
                                  disabled={
                                    Array.isArray(selectedContacts) &&
                                    selectedContacts
                                      .map((item) => item?.id)
                                      .includes(user?.id)
                                  }
                                />
                              </div>
                            </td>
                          )}

                          {/* user name,email,pic */}
                          <td>
                            <div className="d-flex align-items-center">
                              {/* <div className='symbol symbol-45px me-5'>
                            <img src={toAbsoluteUrl('/media/avatars/150-11.jpg')} alt='' />
                          </div> */}
                              <div className={`symbol symbol-circle symbol-50px overflow-hidden me-3 ${!crudPermission?.edit && 'pe-none'}`}>
                                <div
                                  className="symbol-label"
                                  onClick={() => {
                                    !isSelectCustomerType() &&
                                      history.push(`/crm/contacts/editcustomer`, {
                                        userDetails: user,
                                      });
                                  }}
                                >
                                  {user?.profile_photo_url ? (
                                    <img
                                      src={user?.profile_photo_url}
                                      alt={user?.firstname}
                                      style={{
                                        // minHeight: "100%",
                                        minWidth: "100%",
                                        objectFit: "cover",
                                      }}
                                      className={`w-100 ${
                                        !isSelectCustomerType() &&
                                        "cursor-pointer"
                                      }`}
                                    />
                                  ) : (
                                    <div
                                      className={`symbol-label fs-3 bg-light-danger text-danger text-uppercase ${
                                        !isSelectCustomerType() &&
                                        "cursor-pointer"
                                      }`}
                                    >
                                      {(user?.firstname || " ")[0]}
                                      {(user?.lastname || " ")[0]}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="d-flex justify-content-start flex-column">
                                <span
                                  className={`text-gray-800 fw-bold ${
                                    !isSelectCustomerType() &&
                                    "text-hover-primary cursor-pointer"
                                  } ${!crudPermission?.edit && "pe-none"} text-capitalize mb-1`}
                                  onClick={() => {
                                    !isSelectCustomerType() &&
                                      history.push(`/crm/contacts/editcustomer`, {
                                        userDetails: user,
                                      });
                                  }}
                                >
                                  {user?.firstname + " " + user?.lastname}
                                </span>
                                <span className="text-gray-600 fw-bold">
                                  {user?.email}
                                </span>
                              </div>
                            </div>
                          </td>

                          {/* IS blocked */}
                          {/* <td>
                            {user?.is_blocked ? (
                              <div className="badge badge-light-danger fw-bolder">
                                {intl.formatMessage({
                                  id: "CUSTOMER_MANAGEMENT_CUSTOMER_VALUE_BLOCKED",
                                })}
                              </div>
                            ) : (
                              <div className="badge badge-light-success fw-bolder">
                                {intl.formatMessage({
                                  id: "CUSTOMER_MANAGEMENT_CUSTOMER_VALUE_UNBLOCKED",
                                })}
                              </div>
                            )}
                          </td> */}

                          {/* Address */}
                          <td className="mw-250px">
                            <span className="text-gray-800 fs-6">{address}</span>
                          </td>

                          {/* Labels */}
                          <td className="mw-200px">
                            <div className="d-flex flex-fill flex-row flex-wrap justify-content-center">
                              {user?.labels?.map((label, i) => {
                                return (
                                  <div
                                    key={i}
                                    className="badge badge-light fw-bolder me-2 mb-2"
                                  >
                                    {label.name}
                                  </div>
                                );
                              })}
                            </div>
                          </td>

                          {/* actions */}
                          {!isSelectCustomerType() && (
                            <td>
                              <UsersListActionsDropdown
                                userDetails={user}
                                getCustomersListAPI={getCustomersListAPI}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </>
                )}

                {/* no data */}
                {getCustomersListAPICompleted && customersList.length === 0 && (
                  <tr className="odd">
                    <td valign="top" colSpan={7} className="dataTables_empty">
                      <div className="d-flex flex-column flex-center">
                        <img src={notFound} className="mw-400px" alt="" />
                        <div className="fs-1 fw-bolder text-dark mb-4">
                          {intl.formatMessage({
                            id: "CUSTOMER_MANAGEMENT_LIST_NO_ITEMS_FOUND",
                          })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
            {customersList.length > 0 && (
              <Pagination
                totalPages={paginationDetails?.number_of_pages || 1}
                activePage={activePage}
                onPageClick={onPageClick}
                itemsPerPage={itemsPerPage}
                showItemsPerPage
                setItemsPerPage={(count) => {
                  setItemsPerPage(count);
                }}
              />
            )}
          </div>
          {/* end::Table container */}
        </div>
      </div>
      {/* begin::Body */}
    </div>
  );
};

export default CustomersList;
