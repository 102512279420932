import axios from "axios";
import {
  AddCustomerResponseType,
  CustomerProductResponse,
  CustomerSubscriptionResponse,
  LogsResponseType,
} from "../types/addCustomerResponseType";

// custom
import { GenericResponseType } from "../../../types/GenericReponse";
import { CustomerDetailsResponseType } from "../types/getCustomerDetailsResponseType";
import { CustomersListResponseType } from "../types/getCustomersListResponseType";
import { CustomerNotesResponseType } from "../types/getCustomerNotesResponseTypes";

// get all groups list
export function getCustomers() {
  return axios.get<CustomersListResponseType>("/contacts");
}

//get filter
export function getCustomersListByFilters(
  is_blocked: boolean,
  product_id: number,
  subscription_id: number,
  zip_code: string,
  city: string,
  country_id: number | null,
  labels: number[] | null,
  limit: number,
  search_keyword: string,
  page?: number
) {
  return axios.post<CustomersListResponseType>(
    `/contacts/filter?page=${page ? page : 1}`,
    {
      is_blocked,
      product_id,
      subscription_id,
      zip_code,
      city,
      country_id,
      labels: labels ? JSON.stringify(labels) : null,
      limit,
      search_keyword,
    }
  );
}

// Server should return AuthModel
export function registerCustomer(
  salutation_id: number,
  title_id: number,
  first_name: string,
  last_name: string,
  email: string
  // password: string,
  // password_confirmation: string
  // username: string
) {
  return axios.post<AddCustomerResponseType>("/contact/register", {
    salutation_id,
    title_id: title_id ? title_id : null,
    first_name,
    last_name,
    email,
    // password,
    // password_confirmation,
    // username,
  });
}

// get user details
export function getCustomerDetails(id: number) {
  return axios.get<CustomerDetailsResponseType>(`/contact/${id}`);
}

// update user email
export function updateCustomerEmail(
  id: number,
  email: string,
  confirm_password: string
) {
  return axios.post<CustomerDetailsResponseType>(`/user/updateEmail/${id}`, {
    email,
    confirm_password,
  });
}

// update user password
export function updateCustomerPassword(
  id: number,
  current_password: string,
  password: string,
  password_confirmation: string
) {
  return axios.post<CustomerDetailsResponseType>(`/user/updatePassword/${id}`, {
    current_password,
    password,
    password_confirmation,
  });
}

export function updateCustomerPersonalDetails(
  id: number,
  salutation_id: number,
  title_id: number,
  first_name: string,
  last_name: string,
  email: string,
  telephone: string
  // username: string
) {
  return axios.post<AddCustomerResponseType>(`/user/personal/${id}`, {
    salutation_id,
    title_id: title_id ? title_id : null,
    first_name,
    last_name,
    email,
    telephone,
    // username,
  });
}

export function updateCustomerPersonalAddress(
  id: number,
  address_1: string,
  address_2: string,
  zip_code: number,
  city: string,
  country_id: number
  // state: string
) {
  return axios.post<AddCustomerResponseType>(
    `/contact/personalAddress/create/${id}`,
    {
      address_1,
      address_2,
      zip_code,
      city,
      country_id,
      // state,
    }
  );
}

// get user details
export function deleteCustomerPersonalAddress(id: number) {
  return axios.post<GenericResponseType>(
    `/contact/personalAddress/delete/${id}`
  );
}

// get user details
export function updateCustomerCompanyAddress(id: number, partner_code: string) {
  return axios.post<GenericResponseType>(
    `/contact/companyAddress/create/${id}`,
    {
      partner_code,
    }
  );
}

export function addCustomerCompanyAddress(
  id: number,
  company_type: string | null,
  company_code: string | null,
  name: string,
  email: string,
  tax_number: string,
  VAT: string,
  address_1: string,
  address_2: string,
  zip_code: number,
  city: string,
  country_id: number,
  state_id: number | undefined
) {
  return axios.post<AddCustomerResponseType>(
    `/contact/companyAddress/create/${id}`,
    {
      company_type,
      company_code,
      name,
      email,
      tax_number,
      VAT,
      address_1,
      address_2,
      zip_code,
      city,
      country_id,
      state_id,
    }
  );
}

// get user details
export function deleteCustomerCompanyAddress(id: number, partner_code: string) {
  return axios.post<GenericResponseType>(
    `/contact/companyAddress/delete/${id}`,
    {
      partner_code,
    }
  );
}

// add customer contact
export function addCustomerContact(
  id: number,
  contact_id: number,
  contact_description: string
) {
  return axios.post<GenericResponseType>(
    `/contact/contactDetails/create/${id}`,
    {
      id: contact_id,
      description: contact_description,
    }
  );
}

// update customer contact
export function updateCustomerContact(
  id: number,
  contact_id: number,
  contact_description: string
) {
  return axios.post<GenericResponseType>(
    `/contact/contactDetails/update/${id}`,
    {
      id: contact_id,
      description: contact_description,
    }
  );
}

// delete customer contact
export function deleteCustomerContact(id: number, contact_id: number) {
  return axios.post<GenericResponseType>(
    `/contact/contactDetails/delete/${id}/${contact_id}`
  );
}

// add customer comments
export function addCustomerComments(id: number, comments: string) {
  return axios.post<GenericResponseType>(`/contact/comments/${id}`, {
    comments,
  });
}

// get user details
// export function deleteCustomerCompanyAddress(id: number) {
//   return axios.post<GenericResponseType>(`/customer/companyAddress/delete/${id}`)
// }

// // get user details
// export function deleteCustomerCompanyAddress(id: number) {
//   return axios.post<GenericResponseType>(`/customer/companyAddress/delete/${id}`)
// }

//delete customer
export function deleteCustomer(id: number) {
  return axios.delete<GenericResponseType>(`/user/${id}`);
}

//upload customer profile picture
export function uploadCustomerProfilePicture(id: number) {
  return axios.get<GenericResponseType>(
    `/contact/deleteContactProfilePhoto/${id}`,
    {}
  );
}

// update post categories
export function uploadCustomerLabels(post_id: number, labels_id: number[]) {
  return axios.post<GenericResponseType>(`/contact/labels/${post_id}`, {
    labels_id,
  });
}

// update post categories
export function updateCustomerState(id: number, state: 0 | 1) {
  return axios.post<GenericResponseType>(`/contact/state/${id}`, {
    state,
  });
}

// send verify email
export function verifyCustomerEmail(id: number) {
  return axios.post<GenericResponseType>(
    `/contact/verification/email/${id}`,
    {}
  );
}

//deregister device of contact
export function deregisterDevice(id: number) {
  return axios.post<GenericResponseType>(`/contact/devices/deregister/${id}`, {
    channel: "backend",
  });
}

// change email
export function changeEmail(id: number, email: string) {
  return axios.post<GenericResponseType>(`/contact/changeEmail/${id}`, {
    email: email,
  });
}

// change password
export function changePassword(
  id: number,
  password: string,
  confirmation_password: string
) {
  return axios.post<GenericResponseType>(`/contact/updatePassword/${id}`, {
    password: password,
    confirmation_password: confirmation_password,
  });
}

// get customer logs
export function getCustomerLogs(id: number) {
  return axios.get<LogsResponseType>(`/contact/getLoginsList/${id}`);
}
// get customer logs
export function getCustomerProducts(id: number) {
  return axios.get<CustomerProductResponse>(
    `/contact/products?channel=backend&id=${id}`
  );
}
// get customer logs
export function getCustomerSubscriptions(id: number) {
  return axios.get<CustomerSubscriptionResponse>(
    `/contact/subscriptions?channel=backend&id=${id}`
  );
}

// cancel subscription
export function cancelSubscription(id: number, comments: string) {
  return axios.post<GenericResponseType>(
    `/contact/subscriptions/cancel/${id}`,
    {
      comments: comments,
    }
  );
}

// verify email
export function verifyEMail(id: number) {
  return axios.post<GenericResponseType>(`/contact/doubleOptIn/email/${id}`);
}

export function uploadProfilePicture(
  contacts_id: number,
  profile_photo_id: any
) {
  return axios.post<any>(
    `/contact/profilePicture/${contacts_id}`,
    profile_photo_id,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
}
export function deleteProfilePicture(contacts_id: number) {
  return axios.get<any>(`/contact/deleteContactProfilePhoto/${contacts_id}`);
}

export function explicitlyAddproducts(products_id: number[], id: number) {
  return axios.post<GenericResponseType>(`/contact/products/attach/${id}`, {
    products_id,
  });
}

export function explicitlyRemoveproducts(products_id: number[], id: number) {
  return axios.post<GenericResponseType>(`/contact/products/remove/${id}`, {
    products_id,
  });
}

//update customer comments
export function updateCustomerComment(userId: number, comments: string) {
  return axios.post<CustomerNotesResponseType>(`/contact/comments/${userId}`, {
    comments: comments,
  });
}
